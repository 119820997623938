<template>
  <Popup ref="popupRef" width="850px" :title="title" confirm-text="确认" :confirmLoading="confirmLoading" @confirm="confirm" @close="initForm">
    <el-form label-position="top" inline>
      <template v-if="bizType == 1 || bizType == 2">
        <div class="panel-gray" v-for="(item, index) in form.rechargeTrans" :key="index">
          <div class="flex items-center">
            <el-form-item v-if="bizType == 1" label="订单类型" required style="margin: 0 12px 0 0; flex: 1;">
              <el-select :value="item.orderType" placeholder="请选择订单类型" @change="orderTypeChange($event, index)">
                <el-option
                  v-for="tItem in item.typeList"
                  :key="tItem.type"
                  :label="tItem.text"
                  :value="tItem.type"
                  :disabled="tItem.disabled">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="bizType == 2" label="退款类型" required style="margin: 0 12px 0 0; flex: 1;">
              <el-select :value="item.orderType" placeholder="请选择退款类型" @change="orderTypeChange($event, index)">
                <el-option
                  v-for="tItem in item.typeList"
                  :key="tItem.type"
                  :label="tItem.text"
                  :value="tItem.type"
                  :disabled="tItem.disabled">
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 会员 -->
            <template v-if="item.orderType == 1">
              <el-form-item label="会员等级" required style="margin: 0 12px 0 0; flex: 1;">
                <el-select v-model="item.level" placeholder="请选择会员等级">
                  <el-option
                    v-for="[type, value] in Object.entries(vipTypes)"
                    :key="type"
                    :label="value"
                    :value="Number(type)">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="时长" required style="margin: 0 12px 0 0; flex: 1;">
                <el-select v-model="item.number" placeholder="请选择会员等级">
                  <el-option
                    v-for="[type, value] in Object.entries(vipTimeTypes)"
                    :key="type"
                    :label="value"
                    :value="Number(type)">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="金额" required style="margin: 0 12px 0 0; flex: 1;">
                <el-input placeholder="请输入金额" type="number" v-model="item.amount"></el-input>
              </el-form-item>
            </template>
            <!-- 点券 -->
            <template v-if="item.orderType == 2">
              <el-form-item label="金额" required style="margin: 0 12px 0 0; flex: 1;">
                <el-input placeholder="请输入金额" type="number" v-model="item.amount"></el-input>
              </el-form-item>
              <el-form-item label="数量" required style="margin: 0 12px 0 0; flex: 1;">
                <el-input placeholder="请输入数量" type="number" v-model="item.number" @blur="numberChange(item)"></el-input>
              </el-form-item>
            </template>
            <!-- 报名单 -->
            <template v-if="item.orderType == 3">
              <el-form-item label="金额" required style="margin: 0 12px 0 0; flex: 1;">
                <el-input placeholder="请输入金额" type="number" v-model="item.amount"></el-input>
              </el-form-item>
              <el-form-item label="份数" required style="margin: 0 12px 0 0; flex: 1;">
                <el-input placeholder="请输入份数" type="number" v-model="item.number" @blur="numberChange(item)"></el-input>
              </el-form-item>
            </template>
            <!-- 首页弹窗广告 充值 -->
            <template v-if="item.orderType == 15">
              <el-form-item label="金额" required style="margin: 0 12px 0 0; flex: 1;">
                <el-input placeholder="请输入金额" type="number" v-model="item.amount"></el-input>
              </el-form-item>
              <el-form-item label="时长" required style="margin: 0 12px 0 0; flex: 1;">
                <el-input placeholder="请输入时长(天)" type="number" v-model="item.number" @blur="numberChange(item)"></el-input>
              </el-form-item>
            </template>
            <!-- 点券 退款 -->
            <template v-if="item.orderType == 8">
              <el-form-item label="退款金额" required style="margin: 0 12px 0 0; flex: 1;">
                <el-input placeholder="请输入金额" type="number" v-model="item.amount"></el-input>
              </el-form-item>
              <el-form-item label="退款数量" required style="margin: 0 12px 0 0; flex: 1;">
                <el-input placeholder="请输入数量" type="number" v-model="item.number" @blur="numberChange(item)"></el-input>
              </el-form-item>
            </template>
            <!-- 报名单 退款 -->
            <template v-if="item.orderType == 9">
              <el-form-item label="退款金额" required style="margin: 0 12px 0 0; flex: 1;">
                <el-input placeholder="请输入金额" type="number" v-model="item.amount"></el-input>
              </el-form-item>
              <el-form-item label="退款份数" required style="margin: 0 12px 0 0; flex: 1;">
                <el-input placeholder="请输入份数" type="number" v-model="item.number" @blur="numberChange(item)"></el-input>
              </el-form-item>
            </template>
            <!-- 首页弹窗广告 退款 -->
            <template v-if="item.orderType == 16">
              <el-form-item label="退款金额" required style="margin: 0 12px 0 0; flex: 1;">
                <el-input placeholder="请输入金额" type="number" v-model="item.amount"></el-input>
              </el-form-item>
              <el-form-item label="时长" required style="margin: 0 12px 0 0; flex: 1;">
                <el-input placeholder="请输入时长(天)" type="number" v-model="item.number" @blur="numberChange(item)"></el-input>
              </el-form-item>
            </template>
          </div>
          <el-button v-if="index == form.rechargeTrans.length - 1" type="primary" @click="handleAdd">
            <div class="flex items-center">
              <img class="icon-add" src="@/assets/images/add-w.png">
              <span>新建订单</span>
            </div>
          </el-button>
          <el-button v-else type="danger" @click="handleDelete(index)">
            <div class="flex items-center">
              <img class="icon-add" src="@/assets/images/icon-remove.png">
              <span>删除订单</span>
            </div>
          </el-button>
        </div>
      </template>
      <div v-else>
        <el-form-item label="金额" required>
          <el-input placeholder="请输入金额" type="number" v-model="form.amount"></el-input>
        </el-form-item>
      </div>

      <el-form-item label="注册账号" required>
        <el-input placeholder="请输入注册账号" v-model="form.mobile"></el-input>
      </el-form-item>
      <el-form-item label="支付方">
        <el-input placeholder="请输入支付方姓名或公司" v-model="form.payer"></el-input>
      </el-form-item>
      <el-form-item label="收款方">
        <el-input placeholder="请输入收款方姓名或公司" v-model="form.payee"></el-input>
      </el-form-item>
      <el-form-item label="收款金额" required v-if="bizType == 1">
        <el-input placeholder="请输入收款金额" type="number" v-model="form.amount"></el-input>
      </el-form-item>
      <el-form-item label="退款金额" required v-if="bizType == 2">
        <el-input placeholder="请输入退款金额" type="number" v-model="form.amount"></el-input>
      </el-form-item>

      <el-form-item label="开票状态" style="width: 187px;" required v-if="bizType == 1 || bizType == 2">
        <el-select v-model="form.invoice" placeholder="请选择开票状态">
          <el-option :label="value" :value="Number(type)" v-for="[type, value] in Object.entries(invoiceStatusTypes)" :key="type"></el-option>
        </el-select>
      </el-form-item>
      <template v-if="form.invoice == 2">
        <el-form-item label="发票抬头">
          <el-input placeholder="请输入发票抬头" v-model="form.invoiceHeader"></el-input>
        </el-form-item>
        <el-form-item label="发票信息">
          <el-input placeholder="请输入发票信息" v-model="form.invoiceInfo"></el-input>
        </el-form-item>
      </template>
      <el-form-item label="签约类型" required style="width: 187px;">
        <el-select v-model="form.contractType" placeholder="请选择签约类型">
          <el-option
            v-for="[type, value] in Object.entries(contractTypeTypes)"
            :key="type"
            :label="value"
            :value="Number(type)">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" style="width: 386px;">
        <el-input placeholder="请输入备注" v-model="form.memo"></el-input>
      </el-form-item>
      
      <div>
        <el-form-item label="上传支付凭证" required>
          <div class="img-list">
            <div class="img-item" v-for="(item, index) in form.evidence" :key="index">
              <img class="icon-close" src="@/assets/images/close.png" @click="removeEvidence(index)">
              <LoadImage class="img" :src="item.base64Url" preview></LoadImage>
            </div>
            <UploadImage :autoUpload="false" multiple @success="handleUploadEvidence"></UploadImage>
          </div>
        </el-form-item>
      </div>
    </el-form>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import UploadFile from '@/components/UploadFile'
import UploadImage from '@/components/UploadImage'
import LoadImage from '@/components/LoadImage'
import uploadImageUtil from '@/utils/uploadImageUtil.js'
import { rechargeOrderApi } from '@/api/admin.js'
export default {
  components: {
    Popup,
    UploadFile,
    UploadImage,
    LoadImage
  },
  data() {
    return {
      rechargeTypes: [
        {text: '会员', type: 1, disabled: false},
        {text: '点券', type: 2, disabled: false},
        {text: '报名单', type: 3, disabled: false},
        {text: '首页弹窗广告', type: 15, disabled: false},
        // {text: '保证金', type: 7},
      ],
      refundTypes: [
        {text: '点券', type: 8, disabled: false},
        {text: '报名单', type: 9, disabled: false},
        {text: '首页弹窗广告', type: 16, disabled: false},
        // {text: '保证金', type: 10},
      ],
      vipTimeTypes: {
        30:'月度',
        90:'季度',
        365:'年度',
      },
      vipTypes: {
        1:'青铜会员',
        2:'白银会员',
        3:'黄金会员',
      },
      contractTypeTypes: {
        1: '新签',
        2: '复购',
      },
      invoiceStatusTypes: {
        0: '不需要',
        1: '待开票',
        2: '已开票',
      },
      form: {},
      bizType: 1, // 1-充值 2-退款 3-购买保证金 4-保证金退款
      confirmLoading: false
    }
  },
  computed: {
    title() {
      const map = {
        1: '充值订单表',
        2: '退款订单表',
        3: '缴纳保证金',
        4: '保证金退款',
      }
      return map[this.bizType] || ''
    }
  },
  methods: {
    open(option) {
      this.bizType = option?.type
      this.initForm()
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    initForm() {
      this.form = {
        amount: '',
        contractType: 1,
        evidence: [],
        invoice: 0,
        invoiceHeader: '',
        invoiceInfo: '',
        memo:'',
        mobile: '',
        payee: '',
        payer: '',
        rechargeTrans: [
          {
            amount: '',
            level: '',
            number: '',
            orderType: '',
            typeList: this.bizType == 1 ? JSON.parse(JSON.stringify(this.rechargeTypes)) : this.bizType == 2 ? JSON.parse(JSON.stringify(this.refundTypes)) : []
          }
        ]
      }
    },
    async confirm() {
      if(!this.form.mobile.trim()) return this.$tips({message: '请输入注册账号', type: 'warning'})
      if(!this.form.amount) {
        if(this.bizType == 1 || this.bizType == 3) return this.$tips({message: '请输入收款金额', type: 'warning'})
        if(this.bizType == 2 || this.bizType == 4) return this.$tips({message: '请输入退款金额', type: 'warning'})
      }
      if(this.form.evidence.length == 0) return this.$tips({message: '请上传支付凭证', type: 'warning'})
      // if(this.form.invoice == 2) {
      //   if(!this.form.invoiceHeader.trim()) return this.$tips({message: '请输入发票抬头', type: 'warning'})
      //   if(!this.form.invoiceInfo.trim()) return this.$tips({message: '请输入发票信息', type: 'warning'})
      // }
      const formData = {
        bizType: this.bizType,
        mobile: this.form.mobile.trim(),
        amount: this.form.amount,
        contractType: this.form.contractType,
        invoice: this.form.invoice,
        memo: this.form.memo.trim(),
        payee: this.form.payee.trim(),
        payer: this.form.payer.trim(),
      }
      const rechargeTrans = []
      if(this.bizType == 1 || this.bizType == 2) {
        for (const item of this.form.rechargeTrans) {
          if(!item.orderType) return this.$tips({message: '请选择订单类型', type: 'warning'})
          if(item.orderType == 1) {
            if(!item.level) return this.$tips({message: '请选择会员等级', type: 'warning'})
            if(!item.number) return this.$tips({message: '请选择会员时长', type: 'warning'})
          }
          if(item.orderType == 2 || item.orderType == 8) {
            if(!item.number) return this.$tips({message: '请输入点券数量', type: 'warning'})
          }
          if(item.orderType == 3 || item.orderType == 9) {
            if(!item.number) return this.$tips({message: '请输入报名单数量', type: 'warning'})
          }
          if(item.orderType == 15 || item.orderType == 16) {
            if(!item.number) return this.$tips({message: '请输入时长', type: 'warning'})
          }
          rechargeTrans.push({
            orderType: item.orderType,
            amount: item.amount,
            number: item.number,
            level: item.level
          })
        }
        Object.assign(formData, {
          rechargeTrans
        })
      }
      else if(this.bizType == 3) {
        Object.assign(formData, {
          rechargeTrans: [
            {
              orderType: 7,
              amount: this.form.amount,
            }
          ]
        })
      }
      else if(this.bizType == 4) {
        Object.assign(formData, {
          rechargeTrans: [
            {
              orderType: 10,
              amount: this.form.amount,
            }
          ]
        })
      }
      // if(this.form.orderType == 1) {
      //   if(!this.form.value) return this.$tips({message: '请输入报名单数量', type: 'warning'})
      //   Object.assign(formData, {
      //     value: this.form.value
      //   })
      // } else if(this.form.orderType == 2){
      //   if(!this.form.value) return this.$tips({message: '请选择会员等级', type: 'warning'})
      //   Object.assign(formData, {
      //     value: this.form.value,
      //     time: this.form.time,
      //   })
      // } else if(this.form.orderType == 3){
      //   if(!this.form.value) return this.$tips({message: '请输入点券数量', type: 'warning'})
      //   Object.assign(formData, {
      //     value: this.form.value,
      //   })
      // }
      if(this.form.invoice == 2) {
        Object.assign(formData, {
          invoiceHeader: this.form.invoiceHeader.trim(),
          invoiceInfo: this.form.invoiceInfo.trim(),
        })
      }
      if(this.form.evidence.length > 0) {
        try {
          const evidence = await this.handleUploadFile()
          Object.assign(formData, { evidence: evidence.join(',') })
        } catch (err) {
          this.$tips({message: '文件上传失败', type:'error'})
          return
        }
      }
      console.log('formData', formData)
      this.confirmLoading = true
      rechargeOrderApi(formData).then(res => {
        console.log('新增订单', res)
        this.confirmLoading = false
        this.$tips({message: res.msg, type:'success'})
        this.$emit('update')
        this.close()
      }).catch(err => {
        this.confirmLoading = false
        this.$tips({message: err, type:'error'})
      })
    },
    handleUploadFile() {
      return new Promise(async (resolve, reject) => {
        if(!this.form.evidence.length) {
          resolve([])
          return
        }
        const { uploadImageAsync } = await uploadImageUtil()
        let count = this.form.evidence.length
        const resList = []
        for (const item of this.form.evidence) {
          uploadImageAsync(item.file).then(res => {
            resList.push(res.ossUrl)
            count--
            if(count == 0) {
              resolve(resList)
            }
          }).catch(err => {
            reject(err)
          })
        }
      })
      
    },
    numberChange(item) {
      item.number = isNaN(parseInt(item.number)) ? '' : parseInt(item.number)
    },
    orderTypeChange(e, index) {
      console.log('----e----', e, index)
      const targetTran = this.form.rechargeTrans[index]
      targetTran.number = ''
      this.form.rechargeTrans.forEach((item, i) => {
        if(i != index) {
          item.typeList.forEach(tItem => {
            if(tItem.type == e) tItem.disabled = true
            if(tItem.type == targetTran.orderType) tItem.disabled = false
          })
        }
      })
      targetTran.orderType = e
    },
    handleUploadEvidence(e) {
      if(e && e.length >= 0) {
        this.form.evidence = this.form.evidence.concat(e)
      }
    },
    handleRemoveEvidence(e) {
      if(e && e.index >= 0) {
        this.form.evidence.splice(e.index, 1)
      }
    },
    removeEvidence(index) {
      this.form.evidence.splice(index, 1)
    },
    handleAdd() {
      const typeList = this.bizType == 1 ? JSON.parse(JSON.stringify(this.rechargeTypes)) : this.bizType == 2 ? JSON.parse(JSON.stringify(this.refundTypes)) : []
      for (const tranItem of this.form.rechargeTrans) {
        const target = typeList.find(tItem => tItem.type == tranItem.orderType)
        if(target) {
          target.disabled = true
        }
      }
      this.form.rechargeTrans.push({
        amount: '',
        level: '',
        number: '',
        orderType: '',
        typeList
      })
    },
    handleDelete(index) {
      const deleteTran = this.form.rechargeTrans[index]
      this.form.rechargeTrans.forEach((item, i) => {
        if(i != index) {
          const target = item.typeList.find(tItem => tItem.type == deleteTran.orderType)
          if(target) {
            target.disabled = false
          }
        }
      })
      this.form.rechargeTrans.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>

.img-list {
  display: flex;
  flex-wrap: wrap;
  .img-item {
    position: relative;
    .icon-close {
      position: absolute;
      top: 0;
      right: 0;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
  .img {
    width: 104px;
    height: 104px;
    border-radius: 4px;
    overflow: hidden;
    margin: 0 8px 8px 0;
    border: 1px solid #f5f5f5;
  }
}
.icon-add {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}
.panel-gray {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: #F8F9FA;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 22px;
}
</style>